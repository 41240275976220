<template>
  <form @submit.prevent="submit">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Создание</h2>
      <div>
        <UIButton text="Сохранить" leftIcon="bi-save" type="submit" />
      </div>
    </div>
    <div class="row gap-3">
      <dl class="col row gap-2">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput v-model="data.name" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Шифр:</dt>
            <dl class="col">
              <EditableInput v-model="data.code" editable required />
            </dl>
          </div>
        </div>
      </dl>
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Статус:</dt>
            <dl class="col">
              <EditableSelect
                v-model="data.statusId"
                :options="status"
                editable
                required
              />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import { API_StatusList } from "@/services/api";
import { API_cFGroup_create } from "@/services/references/cFGroup";

export default {
  name: "cFGroupCreate",
  components: { UIButton, EditableInput, EditableSelect },
  data() {
    return {
      data: {
        name: "",
        code: "",
        statusId: null,
      },
      status: [],
    };
  },
  mounted() {
    API_StatusList().then((res) => (this.status = res));
  },
  methods: {
    submit() {
      API_cFGroup_create(this.data).then((res) => {
        this.$router.push({
          path: `/references/technological-maps/cFGroup/${res.id}`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
